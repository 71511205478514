.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dot-typing {
  position: relative;
  left: -9977px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00665e;
  color: #00665e;
  box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e, 10014px 0 0 0 #00665e;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }

  50% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px -10px 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px -10px 0 0 #00665e;
  }

  100% {
    box-shadow: 9984px 0 0 0 #00665e, 9999px 0 0 0 #00665e,
      10014px 0 0 0 #00665e;
  }
}

.dropdown {
  position: absolute;
  z-index: 100;
  top: calc(100% + 10px);
  left: 0;
  right: auto;
  min-width: 120px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown button {
  display: block;
  width: 100%;
  padding: 8px 12px;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown button:hover {
  background-color: #f3f3f3;
}

.annotationLayer {
  height: calc(var(--scale-factor) * 200px) !important;
  display: none !important;
}

.react-pdf__Page__textContent {
  width: 100% !important;
  display: none !important;
  padding: 25px !important;
}

.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.pdf-container canvas {
  max-width: 100%;
  height: auto;
}

.rotateImage {
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (min-width: 1024px) {
  .rotateImage {
    background-image: url("./assets/rotate.png");
  }
}

.loadingSpinner {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000080;
  left: 0%;
  top: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container {
  width: 1116px;
}

.icon-container .lets-icons.progress {
  font-size: 20px;
  animation: rotate 2s linear infinite;
  color: #00665e;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.table-container {
  background-color: #fff;
}

.table-container thead th {
  padding: 0.75rem 1.5rem;
  text-align: left;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #4b5563;
  background-color: #e5e7eb;
  border-bottom: 1px solid #d2d6dc;
  border: 1px solid #d2d6dc;
}

.table-container tbody td {
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 300;
  color: #4b5563;
  border: 1px solid #d2d6dc;
}

.table-container tbody tr {
  border-bottom: 1px solid #d2d6dc;
}

.table-container tbody tr:hover {
  background-color: #f3f4f6;
}

.table-container td.whitespace-nowrap {
  white-space: nowrap;
}

.rdw-emoji-modal {
  top: 21px !important;
  height: 115px !important;
}

.rdw-editor-toolbar {
  padding: 12px 16px !important;
  margin-bottom: 0px !important;
  border: 0px solid white !important;
  background-color: #f5f5f5 !important;
}

.rdw-editor-main {
  border: 0px solid white !important;
}

.rdw-option-wrapper {
  background-color: #f5f5f5 !important;
}

.rdw-option-wrapper:active {
  box-shadow: none !important;
}

.rdw-option-wrapper:hover {
  box-shadow: none !important;
}

.react-avatar__text {
  font-weight: bold !important;
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
}

.rdw-editor-main {
  max-height: 300px !important;
  /* min-height: 150px !important; */
  overflow-y: auto !important;
  padding: 10px !important;
}

.webkit-width {
  width: -webkit-fill-available;
}

.header-height {
  height: calc(100vh - 56px);
}

.pdf-preview {
  border-radius: 6px;
}

.pngFile {
  width: 250px !important;
  height: 250px !important;
}

.markdown-content {

  ul,
  ol {
    padding-left: 2em;
    margin-bottom: 1em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.75px;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  h1 {
    font-size: 1.1em;
  }

  h2 {
    font-size: 1em;
  }

  h3 {
    font-size: 0.8em;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.75px;

  }

  p {
    /* margin-bottom: 8px; */
    font-size: 15px;
    font-weight: 400;
    line-height: 20.75px;
    word-wrap: break-word;
  }

  button {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.75px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
}

.header-heigth-1 {
  height: calc(100vh - 64px);
}

.dashboard-sidebar-container {
  order: 1;
}

.dashboard-content-container {
  order: 2;
}

.dashboard-chat-container {
  order: 3;
  width: 100%;
}

.scrollbar-custom::-webkit-scrollbar {
  width: 3px;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: #555;
}

[type="checkbox"]:checked {
  background: #4c4c4c;
}

.wrapperClassName {
  position: unset !important;
}
.DraftEditor-root {
  position: unset !important;
}
.DraftEditor-editorContainer {
  position: unset !important;
}
.public-DraftStyleDefault-block {
  position: unset !important;
}
.rdw-suggestion-wrapper {
  position: unset !important;
}

/* Dadshboard */
@media screen and (max-width: 767px) {
  .dashboard-icon-container {
    width: 100%;
  }

  .dashboard-sidebar {
    order: 3;
  }

  .dashboard-sidebar-container {
    order: 3;
  }

  .dashboard-content-container {
    order: 1;
  }

  .dashboard-chat-container {
    order: 2;
    width: 100%;
  }

  /* .my-pb-20 {
    padding-bottom: 5rem;
  } */

  .dashboard-sidebar {
    width: 100% !important;
    position: fixed;
    top: 88%;
    left: 0%;
    z-index: 12;
    height: 11% !important;
  }

  .message-editor {
    position: fixed;
    bottom: 12%;
    left: 0%;
    width: calc(100% - 20px);
    background: white;
    margin: 0px 10px;
  }

  .header-heigth-1 {
    height: 60vh;
  }
}

@media screen and (max-width: 479px) {
  .account-table {
    ::-webkit-scrollbar {
      display: none;
    }
  }
}

@media screen and (max-width: 426px) {
  .search-container input {
    font-size: 12px;
  }

  .dashboard-icon-container {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dashboard-content-container {
    width: 100% !important;
  }

  .sidebar {
    width: 100% !important;
  }
}

@media screen and (max-width: 375px) {
  .bg-lendingButton {
    font-size: 12px;
  }
}

.signBtn {
  width: 108px;
  height: 48px;
  text-align: center;
  font-size: 16px;
}
.signBtn.active {
  border-radius: 50px;
  border: 1px solid #7e7e7e;
  background: linear-gradient(90deg, #8934e9, #5844e6);
  background-clip: text; /* Standard property for compatibility */
  -webkit-background-clip: text; /* Vendor prefix for Chrome, Safari */
  -webkit-text-fill-color: transparent; /* Necessary for text transparency */
}

.tagCont {
  padding: 3px;
  border: 1px solid #5e5d6021;
  border-radius: 8px;
}

.tagCont .tagContStack {
  display: flex;
  align-items: center;
}

.tagCont .tagContStack .tagImageCont {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
}

.tagCont .tagContStack .tagImage {
  width: 100%;
  object-fit: cover;
  border-radius: 3px;
}

.tagCont .tagCountCont {
  margin: 0 8px 0 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #1d1c1db3;
  font-weight: 300;
}

.addTagPopupOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 15;
}

.addTagPopupOverlay .addTagPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  height: 700px;
  background-color: white;
  border-radius: 8px;
  padding: 20px 28px;
}

.addTagPopupOverlay .addTagPopup .headerCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addTagPopupOverlay .addTagPopup .headerCont h3 {
  font-weight: 600;
  font-size: 20px;
}

.addTagPopupOverlay .addTagPopup .bodyCont {
  margin-top: 20px;
}

.addTagPopupOverlay .addTagPopup .searchMemberContainer {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #dcdcdc; /* Adjust border style */
  border-radius: 4px;
  padding-left: 10px;
  background-color: #f9f9f9; /* Optional */
  height: 40px; /* Match input height */
}

.addTagPopupOverlay .addTagPopup .searchIcon {
  color: #aaa; /* Adjust color */
  margin-right: 10px;
}

.addTagPopupOverlay .addTagPopup .closeIcon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  color: #666;
  right: 10px;
  cursor: pointer;
}

.addTagPopupOverlay .addTagPopup .searchMemberInput {
  flex: 1;
  border: none;
  outline: none;
  height: 100%;
  font-size: 14px;
  background: transparent;
}

.addTagPopupOverlay .addTagPopup .searchMemberInput::placeholder {
  color: #aaa;
  font-size: 14px;
}

.addTagPopupOverlay .addTagPopup .searchMemberInput:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.addTagPopupOverlay .addTagPopup .searchActive {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.addTagPopupOverlay .addTagPopup .currentTagList {
  display: flex;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: scale(0.5); /* Start small */
  }
  10% {
    opacity: 1;
    transform: scale(1); /* Grow to full size */
  }
  90% {
    opacity: 1;
    transform: scale(1); /* Stay at full size */
  }
  100% {
    opacity: 0;
    transform: scale(0.5); /* Fade out and shrink */
  }
}
.fade-in-out {
  animation: fadeInOut 2s forwards; /* 2s total duration */
}

.modelMenu {
  height: calc(100% - 40px);
}

.hover-effect {
  position: relative;
  transition: all 0.3s ease;
}

.hover-effect:hover {
  border-bottom: 2px solid #3f83f8;
  color: #3f83f8;
  /* box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2);  */
}

.hover-effect::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #3f83f8;
  transition: width 0.3s ease;
}

.hover-effect:hover::after {
  width: 100%;
}

.markdown-content {
  @apply text-base leading-relaxed text-gray-800;
}

.markdown-content p {
  @apply mb-4;
}

.markdown-content h1 {
  @apply text-2xl font-bold mb-4;
}

.markdown-content h2 {
  @apply text-xl font-bold mb-3;
}

.markdown-content h3 {
  @apply text-lg font-bold mb-2;
}

.markdown-content ul,
.markdown-content ol {
  @apply ml-6 mb-4;
}

.markdown-content ul {
  @apply list-disc;
}

.markdown-content ol {
  @apply list-decimal;
}

.markdown-content li {
  @apply mb-1;
}

.markdown-content code {
  @apply px-1 py-0.5 bg-gray-100 rounded text-sm font-mono;
}

.markdown-content pre {
  @apply p-3 mb-4 bg-gray-100 rounded overflow-x-auto;
}

.markdown-content blockquote {
  @apply pl-4 border-l-4 border-gray-200 italic;
}

.markdown-content a {
  @apply text-blue-500 hover:underline;
}

.markdown-content img {
  @apply max-w-full h-auto my-4;
}

.markdown-content table {
  @apply w-full border-collapse mb-4;
}

.markdown-content th,
.markdown-content td {
  @apply border border-gray-300 p-2;
}

.markdown-content th {
  @apply bg-gray-50;
}

.loader {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
