@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif !important;
}


::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FCFCF9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  cursor: pointer;
}

.markdown-content p{
  margin-bottom: 0px !important;
}